import React, { useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactPhotoCollage } from "react-photo-collage";
 //{*Falta importar el photo collage > *}
import useWindowDimensions from '../../hooks/useWindowDimensions';
const content1 = [
    {
        icon: "",
        text: 'Potabilización de Agua de Pozo',
    },
    {
        icon: "",
        text: 'Potabilización de Agua Superficiales',
    },
    {
        icon: "",
        text: 'Plantas Potabilizadoras a Pie de Pozo',
    },
]

const settings = {
    infinite: true,
    speed: 0,
    slidesToShow: 3,
    responsive: [{
        breakpoint: 1200,
        settings: {
            slidesToShow: 3
        }
    },
    {
        breakpoint: 600,
        settings: {
            slidesToShow: 3
        }
    },
    {
        breakpoint: 480,
        settings: {
            slidesToShow: 3
        }
    },
    {
        breakpoint: 400,
        settings: {
            slidesToShow: 2
        }
    }]
};



export const Collage = ({ fotos }) => {
    const { width } = useWindowDimensions();
  
    return (
        <ReactPhotoCollage {...{
            width: '100%',
            height: width <= '767' ? ['40vh'] : ['40vh', '40vh'],
            layout: width <= '767' ? [1] : [2,2],
            photos: fotos,
            showNumOfRemainingPhotos: true
        }} />
    )
}

const DualSliderPotable = () => {
    const slider1 = useRef();
    const slider2 = useRef();

    const [option, setOption] = useState(0);

    const [state, setState] = useState({
        nav1: null,
        nav2: null
    });

    useEffect(() => {
        setState({
            nav1: slider1.current,
            nav2: slider2.current
        })
    }, []);

    const onClick = (id) => {
        slider1.current.slickGoTo(id);
        setOption(id);
    }

    const fotos = [
        { source: require('../../images/gallery/img2.png') },
        { source: require('../../images/gallery/img3.png') },
        { source: require('../../images/gallery/img2.png') },
        { source: require('../../images/gallery/img2.png') },
        { source: require('../../images/gallery/img2.png') },
    ]
    return (
        <div style={{ width: '100%', marginBottom: 80}}>
            <Slider
                {...settings}
                className="project-list"
                ref={slider1}>
                {content1.map((item, id) => (
                    <div key={id} class="item " onClick={() => { onClick(id) }}>
                        <div class="project-owbx" >
                            <h4 class="title">{item.text}</h4>
                        </div>
                    </div>
                ))}
            </Slider>

            {
                option === 0 && (
                    <Collage fotos={fotos} />
                )
            }
            {
                option === 1 && (
                    <Collage fotos={fotos} />
                )
            }
            {
                option === 2 && (
                    <Collage fotos={fotos} />
                )
            }
        </div>
    )
}


export default DualSliderPotable;