import React, { useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Collage } from './DualSliderPotable';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const content1 = [
    {
        icon: "",
        text: 'PLANTAS RESIDENCIALES',
    },
    {
        icon: "",
        text: 'PLANTAS COMERCIALES',
    },
    {
        icon: "",
        text: 'PLANTAS INDUSTRIALES',
    },
    {
        icon: "",
        text: 'PLANTAS MUNICIPALES',
    },

]

const settings = {
    infinite: true,
    speed: 0,
    slidesToShow: 4,
    responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2
          }
        }
    ]
    
};
const DualSliderTratamiento = () => {
    const slider1 = useRef();
    const slider2 = useRef();

    const {width} = useWindowDimensions();

    const [option, setOption] = useState(0);

    const [state, setState] = useState({
        nav1: null,
        nav2: null
    });

    useEffect(() => {
        setState({
            nav1: slider1.current,
            nav2: slider2.current
        })
    }, []);

    const onClick = (id) => {
        slider1.current.slickGoTo(id);
        setOption(id);
    }

    const fotos = [
        { source: require('../../images/gallery/img2.png') },
        { source: require('../../images/gallery/img3.png') },
        { source: require('../../images/gallery/img2.png') },
        { source: require('../../images/gallery/img2.png') },
        { source: require('../../images/gallery/img2.png') },
    ]

    const style = {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    }
    return (
        <div style={{ width: '100%', marginBottom: 80}}>
            <Slider
                {...settings}
                className="project-list"
                ref={slider1}>
                {content1.map((item, id) => (
                    <div key={id}  className="item" onClick={() => { onClick(id) }}>
                        <div class="project-owbx" >
                            <h4 class="title">{item.text}</h4>
                        </div>
                    </div>
                ))}
            </Slider>

            {
                option === 0 && (
                    <Collage fotos={fotos} />
                )
            }
            {
                option === 1 && (
                    <Collage fotos={fotos} />
                )
            }
            {
                option === 2 && (
                    <Collage fotos={fotos} />
                )
            }
             {
                option === 3 && (
                    <Collage fotos={fotos} />
                )
            }
        </div>
    )
}

export default DualSliderTratamiento;