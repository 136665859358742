import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';


class Header extends Component {

    componentDidMount() {
        // sidebar open/close
        var btn = document.querySelector('.navicon');
        var nav = document.querySelector('.header-nav');

        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);

        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () {
                checkLi(this)
            });
        }

        function checkLi(current) {
            const active = current.classList.contains("open")
            navUl.forEach(el => el.classList.remove('open'));
            //current.classList.add('open');

            if (active) {
                current.classList.remove('open')
            } else {
                current.classList.add('open');
            }
        }
    }

    render() {

        return (
            <>
                <header class="site-header mo-left header">
                    <Sticky innerZ={999} enabled={true}>
                        <div class="sticky-header main-bar-wraper navbar-expand-lg">
                            <div class="main-bar clearfix ">
                                <div class="container clearfix">

                                    <div class="logo-header mostion">
                                        <Link to={'/'}><img src={require('../../images/Logo_H.png')} alt="" /></Link>
                                    </div>

                                    <button class="navbar-toggler navicon justify-content-end" type="button"
                                        data-toggle="collapse" data-target="#navbarNavDropdown"
                                        aria-controls="navbarNavDropdown" aria-expanded="false"
                                        aria-label="Toggle navigation">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>



                                    <div class={`header-nav navbar-collapse collapse justify-content-end`}
                                        id="navbarNavDropdown">
                                        <div class="logo-header d-md-block d-lg-none">
                                            <Link to={'/'}><img src={require('../../images/Logo_H.png')} alt=""
                                                style={{ height: "69px" }} /></Link>
                                        </div>

                                        {/* Inicia Navbar */}
                                        <ul class="nav navbar-nav">
                                            <li className="has-mega-menu"><Link to={'/'}>Inicio</Link></li>
                                            <li className="has-mega-menu" style={{ borderWidth: 0 }}><Link to={'/Tratamiento-de-agua'}>Aguas Residuales</Link></li>
                                            <li className="has-mega-menu"><Link to={'/Potabilizacion'}>Potabilización</Link></li>
                                            <li className="has-mega-menu"><Link to={'/Servicios-y-Productos'}>Servicios</Link></li>
                                            <li className="has-mega-menu"><Link to={'/Equipos'}>Equipos</Link></li>
                                        </ul>
                                        {/* Termina Navbar */}

                                        <div class="dlab-social-icon">
                                            <ul>
                                                <li><Link class="site-button circle fa fa-facebook" to={'/#'}></Link>
                                                </li>
                                                <li><Link class="site-button  circle fa fa-twitter" to={'/#'}></Link>
                                                </li>
                                                <li><Link class="site-button circle fa fa-linkedin" to={'/#'}></Link>
                                                </li>
                                                <li><Link class="site-button circle fa fa-instagram" to={'/#'}></Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Sticky>

                </header>

            </>
        )
    }
}

export default Header;



