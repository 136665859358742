import React from 'react';
import { Link } from 'react-router-dom';

const bg2 = require('../../../images/purify/purify2.png')

const Diferenciadores = () => {
    return (

        <div className="section-full content-inner-1 overlay-black-dark about-8-service bg-img-fix"
            style={{ backgroundImage: "url(" + bg2 + ")" }}>
            <div className="container">
                <div className="section-head text-white text-center">
                    <h3 className="title-box m-tb0 max-w800 m-auto">Contáctanos y en menos de 48 horas un ejecutivo
                        te contactará para apoyarte con cualquier duda relacionada con tu proyecto. <span className="bg-primary"></span>
                    </h3>
                    <br />
                    <h4>Nuestros diferenciadores en el mercado:</h4>
                </div>
            </div>
            <div className="container">
                <div className="row text-white">
                    <div className="col-lg-4 col-md-4 m-b30">
                        <div className="icon-bx-wraper bx-style-1 p-a30 center">
                            <div className="icon-lg text-white m-b20">
                                <img src={require('../../../images/icon/service.png')} alt="service" />
                            </div>
                            <div className="icon-content">
                                <h5 className="dlab-tilte text-uppercase">Servicio Integral</h5>
                                <p>Calidad y servicio integral en la implementación de tus proyectos.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 m-b30">
                        <div className="icon-bx-wraper bx-style-1 p-a30 center">
                            <div className="icon-lg text-white m-b20">
                                <img src={require('../../../images/icon/quality.png')} alt="service" />
                            </div>

                            <div className="icon-content">
                                <h5 className="dlab-tilte text-uppercase">Calidad</h5>
                                <p>Mejores estándares de calidad y equipos utilizados.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 m-b30">
                        <div className="icon-bx-wraper bx-style-1 p-a30 center">
                            <div className="icon-lg text-white m-b20">
                                <img src={require('../../../images/icon/experience.png')} alt="service" />
                            </div>
                            <div className="icon-content">
                                <h5 className="dlab-tilte text-uppercase">Experiencia</h5>
                                <p>Experiencia en más de 50 industrias con más de 35 años de servicio</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="choses-info text-white">
                <div className="container-fluid">
                    <div className="row choses-info-content">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6 p-a30 wow zoomIn"
                            data-wow-delay="0.2s">
                            <h2 className="m-t0 m-b10 font-weight-400 font-45"><i
                                className="flaticon-alarm-clock m-r10"></i><span
                                    className="counter">+35</span></h2>
                            <h4 className="font-weight-300 m-t0">Años en el mercado</h4>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6 p-a30 wow zoomIn"
                            data-wow-delay="0.4s">
                            <h2 className="m-t0 m-b10 font-weight-400 font-45"><i
                                className="flaticon-worker m-r10"></i><span className="counter">+700</span>
                            </h2>
                            <h4 className="font-weight-300 m-t0">Proyectos con Clientes Satisfechos</h4>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6 p-a30 wow zoomIn"
                            data-wow-delay="0.6s">
                            <h2 className="m-t0 m-b10 font-weight-400 font-45"><i
                                className="flaticon-settings m-r10"></i><span className="counter">+80</span>
                            </h2>
                            <h4 className="font-weight-300 m-t0">Expertos Técnicos</h4>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6 p-a30 wow zoomIn"
                            data-wow-delay="0.8s">
                            <h2 className="m-t0 m-b10 font-weight-400 font-45"><i
                                className="flaticon m-r10"></i><span
                                    className="counter">+50</span></h2>
                            <h4 className="font-weight-300 m-t0">Industrias Trabajadas</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Diferenciadores;