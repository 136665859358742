import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'

const bg = require('../../images/banner/bnr1.jpg')

class PrivacyPolicy extends Component {


    render() {
        return (
            <>
                <Header/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Aviso de Privacidad</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>Aviso de Privacidad</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content-block">
                        <div class="section-full content-inner inner-text">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h4 class="title"></h4>
                                        <p class="font-18">*De acuerdo en lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, (LFPDPPP), (en adelante la "Ley"), declara Eco Intellutions, S.A de C.V.  en lo sucesivo "Eco Intellutions" una empresa legalmente constituida de conformidad con las leyes mexicanas, con domicilio en Minerva No. 72, col. Crédito Constructor, C.P. 03940, CDMX; como responsables del tratamiento de sus datos personales, por lo que a continuación hacen de su conocimiento la política de privacidad aplicada para estos fines.</p>

                                        <div className="dlab-divider bg-gray-dark"></div>
                                        <h4 className="title">Datos personales que se pueden recabar</h4>
                                        <p className="font-18">"Eco Intellutions" recabará todos los datos personales de
                                            los clientes y/o proveedores que sean necesarios,
                                            como ejemplo de estos datos (más no limitándose únicamente a los aquí
                                            mencionados) se encuentran: Nombre, apellido, correo electrónico,
                                            fecha de nacimiento, lugar de nacimiento, país de nacimiento, nacionalidad,
                                            ocupación, nivel de ingresos, domicilio particular, números telefónicos,
                                            Clave Única de Registro de Población (CURP), RFC, entre otros, comprobantes
                                            o documentos oficiales que acrediten su identidad y la información
                                            proporcionada.
                                            El cliente declara que al llenar la solicitud y proporcionarnos sus datos,
                                            tenemos su acuerdo tácito para su verificación.</p>

                                        <div class="dlab-divider bg-gray-dark"></div>
                                        <h4 class="title">Uso de la información</h4>
                                        <p class="font-18"> "Eco Intellutions" utiliza y procesa los datos recabados de los clientes y proveedores de manera confidencial, con la finalidad de identificar debidamente al cliente o proveedor según sea el caso, para contactarlo. Para prevenir el acceso no autorizado a sus datos personales, hemos establecido diversos procedimientos, así como el uso de medios técnicos, administrativos y digitales que permiten el tratamiento debido de dicha información.
                                            Así mismo, se informa que mantenemos la información de nuestros clientes y proveedores  por un tiempo razonable, y de acuerdo con las disposiciones legales aplicables, por lo que una vez que los datos personales de nuestros clientes o proveedores hayan dejado de ser necesarios para el cumplimiento de lo previsto en el presente aviso de privacidad, y en las disposiciones legales aplicables, serán archivados.</p>


                                        <div className="dlab-divider bg-gray-dark"></div>
                                        <h4 className="title">Derechos de acceso, rectificación, cancelación y oposición (ARCO)</h4>
                                        <p className="font-18">Los clientes de "Eco Intellutions" en <b>todo</b> momento pueden ejercer sus derechos de acceso, rectificación, cancelación y oposición (ARCO),
                                            referentes al tratamiento de sus datos personales, siempre y cuando resulte procedente.</p>

                                        <div className="dlab-divider bg-gray-dark"></div>
                                        <h4 className="title">Modificaciones al aviso de privacidad</h4>
                                        <p className="font-18">Este aviso de privacidad podrá ser modificado total o parcialmente por "Eco Intellutions".
                                            Dichas modificaciones serán oportunamente notificadas a los clientes a través de su actualización en la página web
                                            de "Eco Intellutions" www.ecointell.com.mx.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <Footer/>                
            </>
        )
    }
}
export default PrivacyPolicy;