import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import flApp from "../../script/flApp";

const bg = require('../../images/banner/bnr1.jpg')


const fetchData = async (url) => {
	const data = await flApp.content.get('informacionUtil', { orderByChild: 'url', equalTo: url })
		.then((res) => {
			for  (let item in res) {
				if(res[item]["url"]==url){
					console.log(res[item])
					return(res[item])
				}
			}

		})
		.catch();
	return(data)
}


const getImages= async (data) => {
	const imgages = []
	await data
	try{
		imgages.push(
			await flApp.storage.getURL({fileId: data["imagenPreviwe"][0]["id"]})
		)
		imgages.push(
			await flApp.storage.getURL({fileId: data["photoPrincipal"][0]})
		)
		imgages.push(
			await flApp.storage.getURL({fileId: data["imagenArea2"][0]["id"]})
		)
		imgages.push(
			await flApp.storage.getURL({fileId: data["imagenArea3"][0]["id"]})
		)
		return(imgages)
	}
	catch (e){
		console.log(e)
	}

}

class BlogSingle extends Component {

	constructor() {
		super();
		this.state = {
			loding: true,
			data : [],
			img:[]
		}

	}

	componentDidMount() {
		const {handle} = this.props.match.params
		fetchData(handle).then((data) => {
			this.setState({
				loading: false,
				data: data
			})
			getImages(data).then((img)=> {
				this.setState({
					img:img
				})
			})
		})
	}

    render() {
        return (
            <>
                <Header/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">{this.state.data["titulo"]}</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Informacion Util</Link></li>
                                        <li>{this.state.data["titulo"]}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-area">
						<div class="container max-w900">
							<div class="blog-post blog-single">
								<div class="dlab-post-meta">
									<ul>
										<li class="post-date"> <strong>10 Aug</strong> <span> 2016</span> </li>
										<li class="post-author"> Por <Link to="/#">{this.state.data["autor"]}</Link> </li>
									</ul>
								</div>
								<div class="dlab-post-title ">
									<h4 class="post-title m-t0"><Link to="/blog-single-sidebar">{this.state.data["titulo"]}</Link></h4>
								</div>
								<div class="dlab-post-media dlab-img-effect zoom-slow"> 
									<Link to="/blog-single-sidebar"><img src={this.state.img[0]} alt=""/></Link>
								</div>
								<div class="dlab-post-text">
									<p>{this.state.data["p1"]}</p>
									<p>{this.state.data["p2"]}</p>
									<blockquote>{this.state.data["quote"]}</blockquote>
									<p>{this.state.data["p3"]}</p>
									<h5>{this.state.data["subTitulo"]}</h5>
									<div style={{height:"310px"}}>
										<img className="alignleft" width="300" src={this.state.img[1]} alt=""/>
										<p> {this.state.data["parafoArea2"]}</p>
									</div>

									<div class="dlab-divider bg-gray-dark"></div>

									<div style={{height:"300px"}}>
										<img className="alignright" width="300" src={this.state.img[2]} alt=""/>
										<p>{this.state.data["parafoArea2"]}</p>
									</div>

								</div>
								<div>
									<p></p>
								</div>

							</div>

						</div>                 
					</div>
					
                </div>                 
                <Footer/>                
            </>
        )
    }
}
export default BlogSingle;