import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const content = [
    {
        logo: require('../../images/providers/altamira.png'),
    },
    {
        logo: require('../../images/providers/barnes.png'),
    },
    {
        logo: require('../../images/providers/danfoss.jpg'),
    },
    {
        logo: require('../../images/providers/espa.png'),
    },
    {
        logo: require('../../images/providers/F&Q.jpg'),
    },
    {
        logo: require('../../images/providers/flygt-a-xylem-brand-logo-vector.png'),
    },
    {
        logo: require('../../images/providers/franklin.png'),
    },
    {
        logo: require('../../images/providers/Goulds.jpg'),
    },
    {
        logo: require('../../images/providers/grundfos.jpg'),
    },
    {
        logo: require('../../images/providers/hayward.png'),
    },
    {
        logo: require('../../images/providers/impelmex.png'),
    },
    {
        logo: require('../../images/providers/keaser.jpg'),
    },
    {
        logo: require('../../images/providers/meyers.png'),
    },
    {
        logo: require('../../images/providers/mountain-life.png'),
    },
    {
        logo: require('../../images/providers/nabohi.png'),
    },
    {
        logo: require('../../images/providers/ozoni.png'),
    },
    {
        logo: require('../../images/providers/panda.jpg'),
    },
    {
        logo: require('../../images/providers/pedrollo.png'),
    },
    {
        logo: require('../../images/providers/penatir.png'),
    },
    {
        logo: require('../../images/providers/rowa.jpg'),
    },
    {
        logo: require('../../images/providers/shimge.png'),
    },
    {
        logo: require('../../images/providers/SJE-Rhombus-Logo-Vert-2017.jpg'),
    },
    {
        logo: require('../../images/providers/sutorbilt.jpg'),
    },
]
class ProviderSlider extends Component {

    render() {

        const settings = {
            dots: false,
            infinite: true,
            speed: 1000,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        };

        const Border = this.props.hideBorder ? "" : "border"
        const padding = this.props.allowPadding ? "p-a25":""

        return (
            <>
                <Slider {...settings} className="client-logo-carousel btn-style-1 icon-2">
                    {content.map((item, id) => (
                        <div class="item">
                            <div class={`ow-client-logo ${padding}`}>
                                <div class={`client-logo ${Border}`}>
                                    <Link to="#"><img src={item.logo} alt=""/></Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>

            </>
        )
    }
}
export default ProviderSlider;