// Firebase app is always required and must be first
import firebase from 'firebase/app'
// Add additional services that you want to use
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/firestore'
// import 'firebase/messaging'
// import 'firebase/functions'

// Flamelink app is always required
import flamelink from 'flamelink/app'
// Add additional modules that you want to use
import 'flamelink/content'
import 'flamelink/storage'
// import 'flamelink/settings'
// import 'flamelink/navigation'
// import 'flamelink/users'



const config = {
    apiKey: "AIzaSyAYnprxWKypfEQr6MEKxgyyUivvT6knrFw",
    authDomain: "ecointel-83b8c.firebaseapp.com",
   // databaseURL: 'ecointel-83b8c.firebaseapp.com',
    projectId: "ecointel-83b8c",
    storageBucket: "ecointel-83b8c.appspot.com",
    messagingSenderId: "45455310565",
    appId: "1:45455310565:web:110a77457275b953999e0c",
    measurementId: "G-3LH1W1222S"
};
const firebaseApp = firebase.initializeApp(config);

const flApp = flamelink({
    firebaseApp,
    env: 'production', // optional, defaults to `production`
    locale: 'en-US', // optional, defaults to `en-US`
    dbType: 'cf' // optional, defaults to `rtdb` - can be 'rtdb' or 'cf' (Realtime DB vs Cloud Firestore)
})

export default flApp