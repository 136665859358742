import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'

const bg = require('../../images/banner/bnr1.jpg')

class Contact4 extends Component {


    render() {
        return (
            <>
                <Header/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">Agenda una cita</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/index">Home</Link></li>
                                        <li>Agenda una cita</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="section-full content-inner bg-white contact-style-1">
                        <div className="container">
                            <div className="row dzseth">
                                <div className="col-lg-3 col-md-6 col-sm-6 m-b30 offset-1">
                                    <div className="icon-bx-wraper bx-style-1 p-lr20 p-tb30 center seth radius-sm">
                                        <div className="icon-lg text-primary m-b20"> <Link to="/#" className="icon-cell"><i className="ti-location-pin"></i></Link> </div>
                                        <div className="icon-content">
                                            <h5 className="dlab-tilte text-uppercase">Dirección</h5>
                                            <p className="op7">Minerva No. 72
                                                Col.Crédito Constructor,
                                                03940, CDMX.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                    <div className="icon-bx-wraper bx-style-1 p-lr20 p-tb30 center seth radius-sm">
                                        <div className="icon-lg text-primary m-b20"> <Link to="/#" className="icon-cell"><i className="ti-email"></i></Link> </div>
                                        <div className="icon-content">
                                            <h5 className="dlab-tilte text-uppercase">Correo</h5>
                                            <p className="m-b0 op7">ventas@ecointell.com.mx</p>
                                            <p className="op7">contacto@ecointell.com.mx</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                    <div className="icon-bx-wraper bx-style-1 p-lr20 p-tb30 center seth radius-sm">
                                        <div className="icon-lg text-primary m-b20"> <Link to="/#" className="icon-cell"><i className="ti-mobile"></i></Link> </div>
                                        <div className="icon-content">
                                            <h5 className="dlab-tilte text-uppercase">Llámanos</h5>
                                            <p className="m-b0 op7"> 55-56-63-53-09</p>
                                            <p className="op7"> 55-56-61-10-69</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-lg-6 m-b30">
                                    <div className="p-a30 bg-gray clearfix radius-sm">
                                        <h3>Deja tus datos y en breve te contactaremos</h3>
                                        <div className="dzFormMsg"></div>
                                        <form method="post" className="dzForm" action="script/contact.php">
                                            <input type="hidden" value="Contact" name="dzToDo" />
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input name="dzName" type="text" required className="form-control" placeholder="Nombre"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <div className="input-group"> 
                                                            <input name="dzEmail" type="email" className="form-control" required  placeholder="ejemplo@correo.com" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input name="dzOther[Phone]" type="text" required className="form-control" placeholder="Teléfono"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input name="dzOther[Subject]" type="text" required className="form-control" placeholder="Asunto"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <textarea name="dzMessage" rows="4" className="form-control" required placeholder="Más información sobre tu duda o proyecto..."></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                            <input className="form-control d-none"style={{display:"none"}} data-recaptcha="true" required data-error="Please complete the Captcha"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <button name="submit" type="submit" value="Submit" className="site-button "> <span>Submit</span> </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                
                                <div className="col-lg-6 m-b30 d-flex">
                                    <iframe title="map" src="https:www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3764.095163825846!2d-99.18199238537295!3d19.365031686922727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff8dec439a87%3A0x32f99fad554ef0b1!2sMinerva%2072%2C%20Cr%C3%A9dito%20Constructor%2C%20Benito%20Ju%C3%A1rez%2C%2003940%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1622826097879!5m2!1ses-419!2smx" className="align-self-stretch radius-sm" style={{border:"0",width:"100%",minHeight:"100%"}} allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <Footer/>                
            </>
        )
    }
}
export default Contact4;