import React, {Component, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import Sidebar from '../element/sidebar';
import flApp from "../../script/flApp";
import {Ellipsis} from 'react-spinners-css';




const bg = require('../../images/banner/bnr1.jpg')


const fetchCasos = async () => {
	try {
		const data = await flApp.content.get({schemaKey: 'informacionUtil'})

		const test = []

		for  (let item in data) {
			console.log("item",data[item])
			await flApp.storage.getURL({
				fileId: data[item]["photoPrincipal"][0],
			}).then((url)=> {

				const tmp2 = {
					id:  data[item]["id"],
					titulo: data[item]["titulo"],
					desc: data[item]["description"],
					dia: data[item]["dia"],
					autor: data[item]["autor"],
					imgUrl: url,
					link: '/InformacionUtil/'+data[item]["url"]
				}
				console.log("tmp2",tmp2)
				test.push(tmp2)
			})

		}
		return(test)
	}
	catch (e){
		console.log(e)
	}


}



class InformacionUtil extends Component {

	constructor() {
		super();
		this.state={
			list:[],
			loading: true,
		}

	}

	componentDidMount(){

		fetchCasos().then((images) => {
			this.setState({
				list: images,
				loading: false,
			})
		})

	}

	render() {
		return (
			<>
				<Header/>
				<div class="page-content bg-white">

					{/*start hedder*/}
					<div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
						<div class="container">
							<div class="dlab-bnr-inr-entry">
								<h1 class="text-white">Información Util</h1>
								<div class="breadcrumb-row">
									<ul class="list-inline">
										<li><Link to="/index">Inicio</Link></li>
										<li>Información Util</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					{/*end hedder*/}

					<div class="content-area">
						<div class="container">
							<div class="row">

								<Sidebar/>
								{this.state.loading && (
									<div className="loading" style={{marginLeft:"40%"}}>
										 <Ellipsis color="#0064B4" />
									</div>
								)}

								{/*start posts*/}
								<div class="col-lg-9 col-md-7">
									<div id="masonry" class="dlab-blog-grid-2">

										<div class="row">
											{
												this.state.list.map((item)=>(
													<div className="post card-container col-lg-4 col-md-12 col-sm-12" >
														<div className="blog-post blog-grid blog-rounded blog-effect1">
															<div className="dlab-post-media dlab-img-effect">
																<a href="blog-single"><img
																	src={item.imgUrl}
																	alt=""/></a>
															</div>
															<div className="dlab-info p-a20 border-1">
																<div className="dlab-post-meta">
																	<ul>
																		<li className="post-date"><strong>10
																			Aug</strong> <span> 2021</span></li>
																		<li className="post-author"> Por <a
																			href="javascript:void(0);">{item.autor}</a></li>
																	</ul>
																</div>
																<div className="dlab-post-title">
																	<h4 className="post-title"><a href="blog-single">{item.titulo}</a></h4>
																</div>
																<div className="dlab-post-text">
																	<p>{item.desc}</p>
																</div>
																<div className="dlab-post-readmore">
																	<a href={item.link} title="READ MORE"
																	   rel="bookmark" className="site-button">LEER MAS
																		<i className="ti-arrow-right"></i>
																	</a>
																</div>
															</div>
														</div>
													</div>
												)
												)
											}


										</div>

									</div>
								</div>
								{/*end posts*/}



							</div>
						</div>
					</div>

				</div>
				<Footer/>
			</>
		)
	}
}
export default InformacionUtil;