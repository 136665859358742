import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import ClientSlider1 from '../element/clientSlider1';
import ProviderSlider from "../element/providerSlider";

const bg = require('../../images/purify/equipos.png')

class Equipos extends Component {


    render() {
        return (
            <>
                <Header/>

                <div class="page-content bg-white">

                    <div class="dlab-bnr-inr overlay-black-middle bg-pt"  style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Nuestros Equipos</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="/">Inicio</Link></li>
                                        <li>Nuestros Equipos</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content-block">
                        <div class="section-full content-inner">
                            <div class="container">
                                <div class="section-head text-black text-center ">

                                    <h2 class="title">Equipos</h2>
                                    <br/>
                                    <div className="row">
                                        <div className="col-12">
                                         <h3> Contamos con una variedad de equipos a utilizar en tu próximo proyecto.
                                             Habla con alguno de nuestros especialistas para conocer más. </h3>
                                            <Link to="/contact-4" className="site-button align-self-center outline ml-auto outline-2 btnhover14"> Agenda una Cita</Link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="col-6" style={{textAlign: "justify"}}>
                    <ul style={{marginLeft:"10%"}}>
                        <h1 style={{marginLeft:"15%"}}>Catálogo</h1>
                        <br/>
                        <li>Bombas de superficie (periféricas, centrifugas, tipo jet, verticales recirculadoras)</li>
                        <li>Bombas sumergibles (agua limpia, negra, lodos, para desazolve)</li>
                        <li>Presurizadores</li>
                        <li>Aireadores de turbina y hélice</li>
                        <li>Equipos hidroneumáticos sencillos, dúplex, triplex (velocidad variable y velocidad constante) </li>
                        <li>Equipos sumergibles para cárcamo (aguas negras) y pozo profundo (aguas limpias)</li>
                        <li>Tanques precargados, filtros de arenas, filtros de lecho profundo, filtros de cartucho, lámparas UV y cloradores de pastilla y líquidos</li>
                        <li>Soplador regenerativo y lobular</li>
                        <li>Motores sumergibles y de superficie</li>
                        <li>Tableros alternadores-simultaneadores</li>
                        <li>Accesorios y químicos para piscina</li>
                        <li>Mezcladores químicos</li>
                        <li>Celdas fotovoltaicas</li>
                    </ul>
                </div>
                <div style={{textAlign: "justify", marginLeft:"60px"}}>
                    <Link to="/" className="site-button align-self-center outline ml-auto outline-2 btnhover14"> Descarga nuestro catálogo</Link>
                </div>

                    <br/>
                    <div className="section-full dlab-we-find bg-img-fix p-t20 p-b20 bg-white wow fadeIn"
                         data-wow-duration="2s" data-wow-delay="0.6s">
                        <div className="container">
                            <div className="section-content">
                                <div></div>
                                <h2 className="title"> Trabajamos con las mejores marcas</h2>
                                <div/>
                                <ProviderSlider hideBorder/>
                            </div>
                        </div>
                    </div>

                <Footer hideContactInfo displayNewsLetter />
            </>
        )
    }
}
export default Equipos;