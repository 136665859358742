import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <>

                <footer class="site-footer style1">

                    {this.props.displayNewsLetter &&

                    (<div class="dlab-newsletter">
                        <div class="container">
                            <div class="ft-contact wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s">
                                <div class="ft-contact-bx">
                                    <img src={require("../../images/icon/icon1.png")} alt=""/>
                                    <h4 class="title">Dirección</h4>
                                    <p>Minerva No. 72 Col. Crédito Constructor, Alcaldía Benito Juárez, C.P. 03940 Ciudad de México</p>
                                </div>
                                <div class="ft-contact-bx">
                                    <img src={require("../../images/icon/icon2.png")} alt=""/>
                                    <h4 class="title">Teléfonos</h4>
                                    <p><ul>
                                        <li>55-56-63-53-09</li>
                                        <li>55-56-61-10-69</li>
                                    </ul></p>
                                </div>
                                <div class="ft-contact-bx">
                                    <img src={require("../../images/icon/icon3.png")} alt=""/>
                                    <h4 class="title">Correo </h4>
                                    <p><ul>
                                        <li>ventas@ecointell.com.mx</li>
                                        <li>contacto@ecointell.com.mx</li>
                                    </ul></p>
                                </div>
                            </div>
                        </div>
                    </div>)}

                    {!this.props.hideContactInfo &&

                    (<div class="section-full p-t50 p-b20 bg-primary text-white overlay-primary-dark footer-info-bar">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-6 m-b30">
                                    <div class="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
                                        <div class="icon-content">
                                            <h5 class="dlab-tilte">
                                                <span class="icon-sm"><i class="ti-location-pin"></i></span>
                                                Dirección
                                            </h5>
                                            <p class="op7">Minerva No. 72
                                                Col.Crédito Constructor,
                                                03940, CDMX.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 m-b30">
                                    <div class="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
                                        <div class="icon-content">
                                            <h5 class="dlab-tilte">
                                                <span class="icon-sm"><i class="ti-email"></i></span>
                                                Correo
                                            </h5>
                                            <p class="m-b0 op7">ventas@ecointell.com.mx</p>
                                            <p class="op7">contacto@ecointell.com.mx</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 m-b30">
                                    <div class="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
                                        <div class="icon-content">
                                            <h5 class="dlab-tilte">
                                                <span class="icon-sm"><i class="ti-mobile"></i></span>
                                                Teléfono
                                            </h5>
                                            <p class="m-b0 op7"> 55-56-63-53-09</p>
                                            <p class="op7"> 55-56-61-10-69</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 m-b30">
                                    <div class="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
                                        <div class="icon-content">
                                            <h5 class="dlab-tilte">
                                                <span class="icon-sm"><i class="ti-alarm-clock"></i></span>
                                                Horarios de Oficina
                                            </h5>
                                            <p class="m-b0 op7"> Lunes a Viernes</p> <p> 10:00 - 18.00</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)}

                    <div class="footer-top">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-4 col-sm-6">
                                    <div class="widget widget_about">
                                        <h4 class="footer-title">Sobre nosotros</h4>
                                        <p>Estamos comprometidos con dar un servicio de calidad. Somos líderes en la industria
                                            gracias a los 30 años de experiencia que nos respaldan.</p>
                                        <Link to={"/nosotros"} class="readmore"> Leer más</Link>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="widget">
                                        <h4 class="footer-title"> Links útiles </h4>
                                        <ul class="list-2">
                                            <li><Link to={"/nosotros"}>Nosotros</Link></li>
                                            <li><Link to={"/Informacion-Util"}>Información Útil</Link></li>
                                            <li><Link to={"/Servicios-y-Productos"}>Servicios</Link></li>
                                            <li><Link to={"/privacy-policy"}>Aviso de Privacidad</Link></li>
                                            <li><Link to={"/contacto"}>Contacto</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12">
                                    <div class="widget widget_subscribe">
                                        <h4 class="footer-title">Mantente conectado</h4>
                                        <p>Suscríbete a nuestro Newsletter para recibir información relevante.</p>
                                        <form class="dzSubscribe" action="script/mailchamp.php" method="post">
                                            <div class="dzSubscribeMsg"></div>
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input name="dzEmail" required="required" type="email" class="form-control" placeholder="tucorreo@ejemplo.com"/>
                                                    <div class="input-group-addon">
                                                        <button name="submit" value="Submit" type="submit" class="site-button fa fa-paper-plane-o"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="footer-bottom footer-line">
                        <div class="container">
                            <div class="footer-bottom-in">
                                <div class="footer-bottom-logo"><Link to={"/"}><img src={require("../../images/Logo_H.png")} alt=""/></Link></div>
                                <div class="footer-bottom-social">
                                    <ul class="dlab-social-icon dez-border">
                                        <li><Link class="fa fa-facebook" to={"https://www.facebook.com/ecointellutions"}></Link></li>
                                        <li><Link class="fa fa-linkedin" to={"/#"}></Link></li>
                                        <li><Link class="fa fa-youtube-play" to={"/#"}></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </footer>

            </>
        );
    }
}

export default Footer;