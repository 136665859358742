import React from 'react';
import Header from '../layout/header'
import Footer from '../layout/footer'
import HydroAction from './hydroAction';
import styled from 'styled-components'

import img from '../../images/imagenes-principales/IMG_4396.JPG';

const Container = styled.div`
    width: 100%; 
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    align-content: center;

    .title{
        font-size: 26px;
        width:80%;
        color:#333;
        font-weight: 700;
        padding-left: 10px;

        @media screen and (max-width: 767px){
            font-size: 22px;
            width: 100%;
            padding-left: 0;
            margin-top: -30px;
        }

    }

    .imgMobile{
        display: none;
    }

    @media screen and (max-width: 767px){
        .imgMobile{
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            width: 100%;
            height: 273px;
            overflow: 'hidden';
            background-image: url(${img});
        }
    }


`
const ImgsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    div{
        justify-self: center;
    }

    @media screen and (max-width: 767px){
            margin-top: -40px;
        
            .img2, .img3,.img1{
                display: none !important;
                background: red;
        }
    }

`;


const CategoryContainer = styled.div`

    .title{
        font-size: 30;
        font-weight: bold;
        color: #333;
        margin: 0;

        @media screen and (max-width: 767px){
            margin-left: 0;
        }
    }
`
const TextsContainer = styled.div`
    margin-top: 50px;
    margin-bottom: 50px;
    display: grid;
    width: 80%;
    grid-template-columns: 35% 31% 33%;
    grid-template-rows: auto;
    row-gap: 30px;
    grid-template-areas: 
        "text1 . text2"
        "text3 . text4";

    @media screen and (max-width: 767px){
        grid-template-columns: 100%;
        grid-template-areas: 
            "text1"
            "text2"
            "text3"
            "text4";

        width: 100%;
        padding: 0;
    }

    .text1{
        grid-area: text1;
    }
  
    .text2{
        grid-area: text2;
    }

    .text3{
        grid-area: text3;
    }
  
    .text4{
        grid-area: text4;
    }

    div{
        margin-left: 20px;

        @media screen and (max-width: 767px){
            margin-left: 0;
        }
    }
  

`;





const CategoryComponent = ({ title, text1, text2 }) => {
    return (
        <CategoryContainer>
            <p className="title">{title}</p>

            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                <div style={{ width: 10, height: 10, borderRadius: 50, background: "#4EC6F3", marginRight: 10 }}></div>
                <p style={{ color: '#333', margin: 0 }}>{text1}</p>

            </div>

            {text2 && (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <div style={{ width: 10, height: 10, borderRadius: 50, background: "#4EC6F3", marginRight: 10 }}></div>
                    <p style={{ color: '#333', margin: 0 }}>{text2}</p>

                </div>

            )}
        </CategoryContainer>
    )
}

const Infraestructura = () => {
    return (

        <Container>
            <Header />
            <div className="imgMobile"></div>
            <div className="container" style={{ height: '100%', display: 'flex', alignItems: 'center', paddingTop: 30, flexDirection: 'column', backgroundColor: 'white', paddingBottom: 50, paddingTop: 50 }}>

                <p className="title">INFRAESTRUCTURA</p>

                <ImgsContainer>
                    <div className="img1">
                        <img src={require('../../images/imagenes-principales/infra1.png')} alt="" />
                    </div>
                    <div className="img2">
                        <img src={require('../../images/imagenes-principales/infra2.png')} alt="" />
                    </div>
                    <div className="img3">
                        <img src={require('../../images/imagenes-principales/infra3.png')} alt="" />
                    </div>
                </ImgsContainer>

                <TextsContainer>
                    <div className="text1">
                        <CategoryComponent
                            title="Diseño"
                            text1="Memorias de cálculo"
                            text2="Memorias de descripción"
                        />
                    </div>
                    <div className="text2">
                        <CategoryComponent
                            title="Instalaciones"
                            text1="Mecánica"
                            text2="Eléctrica"
                        />
                    </div>
                    <div className="text3">
                        <CategoryComponent
                            title="Construcción"
                            text1="Civil"
                        />
                    </div>
                    <div className="text4">
                        <CategoryComponent
                            title="Proyectos"
                            text1="Llave de mano"
                        />
                    </div>
                </TextsContainer>
            </div>



            <HydroAction />


            <Footer hideContactInfo />

        </Container>
    )
}

export default Infraestructura;