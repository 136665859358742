import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer';

// Sliders
import { homeSliderContent3 } from '../element/SliderContent'
import HomeSlider from '../element/homeslider1'
import ScrollToTop from '../element/scrollToTop';

import DualSliderTratamiento from "../element/DualSliderTratamiento";
import useWindowDimensions from '../../hooks/useWindowDimensions';

// import '../../css/skin/skin-2.min.css';

const TratamientoDeAgua = () => {
    
    const { width } = useWindowDimensions();

    return (
        <div style={{ width: '100%', overflow: 'hidden' }}>
            <Header />

            <HomeSlider
                data={homeSliderContent3}
                contentWrapperCls="sliderStyle3"
                hideBtn
            />

            <div className="page-content bg-white">
                <div className='container'>
                    <div className="content-block">
                        <br />
                        <div className="section-head text-center">
                            <h2 className="title"> Sobre nosotros</h2>
                            <br />
                            <p style={{ textAlign: 'justify' }}>Somos una empresa Mexicana con más de 30 años de experiencia,
                                en el campo de la Ingeniería ambiental y especializada en el manejo sustentable del <b>agua</b>.
                                Hemos participado en el Diseño, Planeación, Construcción, Equipamiento, Rehabilitación, Optimización
                                y Operación de más de 400 plantas de tratamiento de aguas residuales a nivel Nacional.</p>
                        </div>
                    </div>
                </div>


                {/* Inicio Plantas Div*/}
                <div className="section-full content-inner bg-white wow fadeIn" data-wow-duration="2s"
                    data-wow-delay="0.4s">
                    <div className="container">
                        <DualSliderTratamiento />
                    </div>
                </div>
                {/* Fin Plantas Div*/}
                <div className="section-full call-action bg-primary wow fadeIn" data-wow-duration="2s" data-wow-delay="0.9s">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 text-white">
                                <h2 className="title">Inicia tu proyecto de desarrollo</h2>
                                <p className="m-b0">Agenda una cita con nuestros especialistas.</p>
                            </div>
                            <div className="col-lg-3 d-flex">
                                <Link to="/contact-4" className="site-button white align-self-center outline ml-auto outline-2 btnhover14"> Agenda una Cita</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <br />

                {/* Inicio Procesos Div*/}

                <div className="procesos">
                    <div className="container">
                        <div className="row d-flex align-items-center justify-content-between">
                            <div className="col-lg-6" style={{ paddingTop: '20px', paddingLeft: width <= 767 ? '30px' : '0px' }}>
                                <h1 className="title text-black">Servicios adicionales:</h1>
                                <ul>
                                    <li>Asesoría, diseño y proyecto.</li>
                                    <li>Equipamiento.</li>
                                    <li>Construcción</li>
                                    <li>Sistemas de tratamiento llave en mano.</li>
                                    <li>Rehabilitaciones.</li>
                                    <li>Operación y mantenimiento.</li>
                                    <li>Instrumentación y control.</li>
                                </ul>

                                <Link to="Servicios-y-productos" class="site-button outline outline-2"> Conócenos </Link>
                                <br />
                            </div>


                            <div className="col-lg-6 d-flex align-items-center justify-content-between">
                                <img style={{ marginTop: "85px" }} src={require("../../images/plantas/planta1.png")} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <br />

                {/* Fin Procesos Div*/}

            </div>
            <Footer hideContactInfo displayNewsLetter />
            <ScrollToTop className="style1 radius white" />
        </div>
    )
}

export default TratamientoDeAgua;