import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'

const bg = require('../../images/banner/bnr1.jpg')

class Contacto extends Component {


    render() {
        return (
            <>
                <Header/>
                <div class="page-content bg-white">
                    <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 class="text-white">Contacta con nuestro equipo</h1>
                                <div class="breadcrumb-row">
                                    <ul class="list-inline">
                                        <li><Link to="index">Home</Link></li>
                                        <li>Contacta con nuestro equipo</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="section-full content-inner contact-page-8 bg-white">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 m-b30">
                                            <div class="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                                                <div class="icon-content">
                                                    <h5 class="dlab-tilte">
                                                        <span class="icon-sm text-primary"><i class="ti-location-pin"></i></span> 
                                                        Dirección
                                                    </h5>
                                                    <p>Minerva No. 72
                                                        Col.Crédito Constructor,
                                                        Alcaldía Benito Juárez, C.P.
                                                        03940, Ciudad de México. </p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-6 m-b30">
                                            <div class="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                                                <div class="icon-content">
                                                    <h5 class="dlab-tilte">
                                                        <span class="icon-sm text-primary"><i class="ti-email"></i></span> 
                                                        Correo
                                                    </h5>
                                                    <p class="m-b0">ventas@ecointell.com.mx</p>
                                                    <p class="m-b0"></p>
                                                    <p class="m-b0">contacto@ecointell.com.mx</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-6 m-b30">
                                            <div class="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                                                <div class="icon-content">
                                                    <h5 class="dlab-tilte">
                                                        <span class="icon-sm text-primary"><i class="ti-whatsapp"></i></span> 
                                                       WhatsApp
                                                    </h5>
                                                    <p class="m-b0">(0155) 5661-1069</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-12 m-b30">
                                    <form class="inquiry-form wow fadeInUp" data-wow-delay="0.2s">
                                        <h3 class="title-box font-weight-300 m-t0 m-b10">Permítenos apoyarte con tu proyecto <span class="bg-primary"></span></h3>
                                        <p> Nuestro equipo está preparado para ayudarte a generar una solución a la medida de tus necesidades, contáctanos.</p>
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="ti-user text-primary"></i></span>
                                                        <input name="dzName" type="text" required class="form-control" placeholder="Nombre"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="ti-mobile text-primary"></i></span>
                                                        <input name="dzOther[Phone]" type="text" required class="form-control" placeholder="Teléfono"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group"> 
                                                        <span class="input-group-addon"><i class="ti-email text-primary"></i></span>
                                                        <input name="dzEmail" type="email" class="form-control" required  placeholder="correo@ejemplo.com" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="ti-check-box text-primary"></i></span>
                                                        <input name="dzOther[Subject]" type="text" required class="form-control" placeholder="Acepto haber leído los Términos y
                                                        condiciones de uso y la <ref> Política de privacidad</ref>"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="ti-file text-primary"></i></span>
                                                        <input name="dzOther[Subject]" type="text" required class="form-control" placeholder="Adjunta un documento"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <span class="input-group-addon"><i class="ti-agenda text-primary"></i></span>
                                                        <textarea name="dzMessage" rows="4" class="form-control" required placeholder="Cuéntanos más sobre tu proyecto"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <button name="submit" type="submit" value="Submit" class="site-button button-md"> <span> Consigue tu cotización</span> </button>
                                            </div>
                                        </div>
                                    </form>	
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <Footer hideContactInfo={true}/>
            </>
        )
    }
}
export default Contacto;