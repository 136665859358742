import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import '../../css/templete.min.css';
import SvgMap from '../element/SvgMap';
import styled from 'styled-components';

var bnr3 = require('../../images/imagenes-principales/NOSOTROS.png');


const ImgContainer = styled.div`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 100%;
    height: 273px;
    overflow: 'hidden';
    background-image: url(${bnr3});
`;

const CoreAboutUs = () => {
    return (
        <div className="container" style={{ marginTop: 40 }}>
            <div className="row">
                <div className="col-12">
                    <p style={{ color: '#0561B2' }}>Nuestra Historia</p>
                </div>
                <div className="col-12">
                    <p style={{ color: '#333333', fontSize: 30, fontWeight: 600 }}>Generamos soluciones inteligentes a nivel Nacional</p>
                    <p style={{ color: '#0561B2', fontSize: 26 }}>Desde hace más de 35 años</p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6">

                    <p style={{ fontSize: 18, fontWeight: 600, color: '#333' }}>¿Quiénes somos?</p>


                    <p>
                        Somos una empresa mexicana con experiencia en el campo de la ingeniería ambiental
                        y especializada en el Diseño, Planeación, Construcción, Equipamiento,  Operación, Rehabilitación,  Mejoras y Optimización  de plantas de tratamiento de aguas residuales, pluviales y potabilizadoras
                        para lograr el óptimo proceso de reciclaje del agua. Cumplimos con la certificación de la norma ISO 9001-2008 de sistemas de gestión de la calidad y contamos con D-U-N-S que pertenece a un sistema
                        desarrollado y regulado por Dun & Bradstreet.
                    </p>

                    <div>
                        <p style={{ color: '#0561B2' }}>Leer Más</p>
                    </div>
                </div>

                <div className="col-lg-6" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <SvgMap />
                </div>
            </div>

        </div>
    )
}

class Nosotros extends Component {


    render() {
        return (
            <>
                <Header />

                <div class="page-content bg-white">

                    <ImgContainer>
                        <div class="container">
                            <div class="dlab-bnr-inr-entry">
                                <h1 style={{ marginTop: 20 }} class="text-white">Nosotros</h1>
                            </div>
                        </div>
                    </ImgContainer>

                    <CoreAboutUs />

                </div>
                <Footer hideContactInfo />
            </>
        )
    }
}
export default Nosotros;