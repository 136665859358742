import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../layout/header'
import Footer from '../../layout/footer'
import flApp from "../../../script/flApp"


const bg = require('../../../images/banner/bnr4.jpg')

const fetchData = async (url) => {

    const data = await flApp.content.get('servicioOProducto', { orderByChild: 'url', equalTo: url })
        .then((res) => {
            for  (let item in res) {
                if(res[item]["url"]==url){
                    return(res[item])
                }
            }

        })
        .catch();
    return(data)
}


const getImages= async (data) => {
    const imgages = []
    await data
    for (const item in data["imagenes"]){
        imgages.push(
            await flApp.storage.getURL({fileId: data["imagenes"][item]["id"]})
        )

    }
    return(imgages)
}

class CasoExito1 extends Component {

    constructor() {
        super();
        this.state = {
            loding: true,
            data : [],
            img:[]
        }

    }



    componentDidMount() {
        const {handle} = this.props.match.params
        fetchData(handle).then((data) => {
            this.setState({
                loading: false,
                data: data
            })
            getImages(data).then((img)=> {
                this.setState({
                    img:img
                })
            })
        })
    }


    render() {
        return (
            <>
                <Header/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">{this.state.data["titulo"]}</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Inicio</Link></li>
                                        <li>Casos de Exito</li>
                                        <li>{this.state.data["titulo"]}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="content-block">
                        <div className="section-full content-inner bg-white">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 m-b30">
                                        <h2 className="text-black font-weight-600 m-b15">{this.state.data["subTitulo"]}</h2>
                                        <p>{this.state.data["parafo1"]}</p>
                                        <p>{this.state.data["parafo2"]}</p>
                                        <div className="row widget widget_getintuch widget_getintuch-pro-details m-lr0">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-user"></i>
                                                    <strong>CLIENTE</strong> {this.state.data["nombreDelCliente"]}
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-location-pin"></i>
                                                    <strong>UBICACIÓN</strong>{this.state.data["ubicacionDelCliente"]}
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-ruler-alt-2"></i>
                                                    <strong>TAMAÑO</strong>{this.state.data["tamanoDeLaPlantaInstalada"]}
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-home"></i>
                                                    <strong>TIPO</strong>{this.state.data["tipoDePlantaInstalada"]}
                                                </div>
                                            </div>
                                        </div>
                                        <h2 className="text-black font-weight-600 m-b15">Alcances</h2>
                                        <p>{this.state.data["parafo3"]}</p>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="icon-bx-wraper m-b30 left">
                                                    <div className="icon-md">
                                                        <Link to="#" className="icon-cell text-primary">
                                                            <i className="flaticon-factory"></i>
                                                        </Link> 
                                                    </div>
                                                    <div className="icon-content">
                                                        <h5 className="dlab-tilte">{this.state.data["subTituloAlcances1"]}</h5>
                                                        <p>{this.state.data["parafoAlcances1"]}</p>
                                                    </div>
                                                </div>
                                                <div className="icon-bx-wraper m-b30 left">
                                                    <div className="icon-md">
                                                        <Link to="#" className="icon-cell text-primary">
                                                            <i className="flaticon-settings"></i>
                                                        </Link> 
                                                    </div>
                                                    <div className="icon-content">
                                                        <h5 className="dlab-tilte">{this.state.data["subTituloAlcances2"]}</h5>
                                                        <p>{this.state.data["parafoAlcances2"]}</p>
                                                    </div>
                                                </div>
                                                <div className="icon-bx-wraper left">
                                                    <div className="icon-md">
                                                        <Link to="#" className="icon-cell text-primary">
                                                            <i className="flaticon-worker"></i>
                                                        </Link> 
                                                    </div>
                                                    <div className="icon-content">
                                                        <h5 className="dlab-tilte">{this.state.data["subTituloAlcances3"]}</h5>
                                                        <p>{this.state.data["parafoAlcances3"]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-lg-12 m-b30">
                                                <img alt="" src={this.state.img[0]}/>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <img alt="" src={this.state.img[1]}/>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <img alt="" src={this.state.img[2]}/>
                                            </div>
                                            <div className="col-lg-12 m-b30">
                                                <img alt="" src={this.state.img[3]}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <Footer/>                
            </>
        )
    }
}
export default CasoExito1;