import React from 'react';
import styled from 'styled-components';
import img from '../../images/about/about3.jpg';

const Container = styled.div`
    width: 70%;
    padding-top: 80px;

    .text{
        width: 80%;
    }

    @media screen and (max-width: 767px){
        width: 90%;

        .text{
            width: 100%;
        }

        .responsive{
            width: 100% !important;
        }

        .title{
            font-size: 20px;
        }
        
    }
`;

const TableContainer = styled.div`
    width: 40%;


    @media screen and (max-width: 767px){
        width: 100%;

       div{
           div{
               p{
                   font-size: 14px !important;
                   line-height: 16px !important;
               }
           }
       }
        
    }
`;

const ImgContainer = styled.div`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 100%;
    height: 273px;
    border-radius: 10px;
    overflow: 'hidden';
    background-image: url(${img});
`;

const ItemTable = ({ color, model, text }) => {
    return (
        <div style={{ display: 'flex', height: 30, flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: { color }, border: '1px solid #E8E8E8', borderTop: '0px' }}>
            <p style={{ width: '50%', textAlign: 'center', margin: 0, color: '#4B4B4B', borderRight: '1px solid #E8E8E8' }}>{model}</p>
            <p style={{ width: '50%', textAlign: 'center', margin: 0, color: '#4B4B4B' }}>{text}</p>
        </div>
    )
}



const ImgComponent = () => {
    return (
        <ImgContainer>
           
        </ImgContainer>
    )
}
const tableData = [
    {
        model: "AP 300",
        text: "1,484 l/d",
        color: "#D2DDF1"
    },
    {
        model: "AP 500",
        text: "2,509 l/d",
        color: "#EAEEF7"
    },
    {
        model: "AP 750",
        text: "3,793 l/d",
        color: "#D2DDF1"
    },
    {
        model: "AP 1000",
        text: "4,845 l/d",
        color: "#EAEEF7"
    },
    {
        model: "AP 1500",
        text: "7,173 l/d",
        color: "#D2DDF1"
    }
]


const HydroAction = () => {
    return (
        <div style={{ marginBottom: 100, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Container >
                <div style={{ marginBottom: 50 }}>
                    <p style={{ fontSize: 30, fontWeight: 'bold', color: '#333' }} className="title">Planta paquete hydro-action</p>
                </div>
                {/* Imagenes */}
                <div style={{ width: '100%', marginBottom: 50, display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <ImgComponent />

                </div>

                <p style={{ fontSize: 16, lineHeight: '20px' }} className="text">
                    La PTAR serie AP de Hydro-Action son plantas de tratamiento aeróbico prefabricada, para aplicaciones residenciales y comerciales ligeras,
                    su capacidad del tratamiento puede ser desde los 300 gpd hasta los 1500 gpd, mediante el uso de sus compresores de aaireación los cuales son
                    capaces de sostener un sistema biológico para tratar aguas residuales de hasta 290 mg/l DBO y 360 mg/l SST respectivamente
                </p>


                <div>
                    <p style={{ fontWeight: 'bold', color: '#333' }}>Rangos de gastos y modelos</p>
                    <div style={{ width: '40%' }} className="responsive">
                        <p style={{ fontWeight: 'bold', color: '#333', textAlign: 'left' }}>IDEAL PARA USO:</p>
                        <p style={{ textAlign: 'left', marginTop: -20, lineHeight: '18px' }}>Residencial - comercios - Restaurantes - Escuelas - Hospitales - Hoteles - Gasolineras - entre otros</p>
                    </div>

                    <TableContainer>

                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', background: '#5B9BD7', justifyContent: 'center', alignItems: 'center', paddingTop: 5, paddingBottom: 5 }}>

                            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <p style={{ width: '90%', textAlign: 'center', color: 'white', fontWeight: 600, lineHeight: '22px', margin: 0 }}>HYDRO-ACTION
                                    MODELOS</p>

                            </div>

                            <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 5, paddingBottom: 5 }}>
                                <p style={{ width: '90%', textAlign: 'center', color: 'white', fontWeight: 600, lineHeight: '22px', margin: 0, fontSize: 16 }}>De 1 a 20 m3/d
                                    (1,000 a 20,000 l/d)
                                </p>

                            </div>
                        </div>
                        {tableData.map((item, key) => {
                            return (
                                <ItemTable
                                    key={key}
                                    model={item.model}
                                    text={item.text}
                                    color={item.color}
                                />
                            )
                        })}

                    </TableContainer>
                    <br />
                    {/* <p style={{ width: '40%', textAlign: 'center', fontSize: 20, fontWeight: 'bold', color: '#333' }} className="text">Planta modular y portátil</p> */}
                </div>

            </Container>
        </div>
    )
}

export default HydroAction;