import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
//import firebase from "../../script/Firestore.js";
import flApp from "../../script/flApp";

//const db = firebase.firestore();
//var storage = firebase.storage();


let images = [
    {
        id: 1,
        name: "agriculture",
        agriculture: true,
        title: "Mining Plant Set Up",
        subTitle: "System",
        imgUrl: require("../../images/portfolio/image_2.jpg"),
        link: "/portfolio-details/Mining-Plant-Set-Up"
    },
    {
        id: 2,
        name: "agriculture",
        agriculture: true,
        title: "Mining Plant Set Up",
        subTitle: "Mining / Plants",
        imgUrl: require("../../images/portfolio/image_2.jpg"),
        link: "/portfolio-details"
    },
    {
        id: 3,
        name: "agriculture",
        agriculture: true,
        title: "sanfran cisco bridge",
        subTitle: "Engineering",
        imgUrl: require("../../images/portfolio/image_3.jpg"),
        link: "/portfolio-details"
    },
    {
        id: 4,
        name: "chemical",
        chemical: true,
        title: "Capturing Manila",
        subTitle: "Energy",
        imgUrl: require("../../images/portfolio/image_4.jpg"),
        link: "/portfolio-details"
    },
    {
        id: 5,
        name: "chemical",
        chemical: true,
        title: "berlin central bank",
        subTitle: "industry",
        imgUrl: require("../../images/portfolio/image_5.jpg"),
        link: "/portfolio-details"
    },
    {
        id: 6,
        name: "chemical",
        chemical: true,
        title: "Mining Plant Set Up",
        subTitle: "Bank / Constructions",
        imgUrl: require("../../images/portfolio/image_6.jpg"),
        link: "/portfolio-details"
    },
    {
        id: 7,
        name: "mechanical",
        mechanical: true,
        title: "Mining Plant Set Up",
        subTitle: "Branding and Identity",
        imgUrl: require("../../images/portfolio/image_7.jpg"),
        link: "/portfolio-details"
    },
    {
        id: 8,
        name: "mechanical",
        mechanical: true,
        title: "hamburg wind energy",
        subTitle: "industry",
        imgUrl: require("../../images/portfolio/image_8.jpg"),
        link: "/portfolio-details"
    },
    {
        id: 9,
        name: "power energy",
        powerenergy: true,
        title: "berlin central bank",
        subTitle: "industry",
        imgUrl: require("../../images/portfolio/image_1.jpg"),
        link: "/portfolio-details"
    },
    {
        id: 10,
        name: "power energy",
        powerenergy: true,
        title: "sanfran cisco bridge",
        subTitle: "Bank / Constructions",
        imgUrl: require("../../images/portfolio/image_9.jpg"),
        link: "/portfolio-details"
    },


];

// const FILTER_DEFS = {
//   agriculture: image => image.agriculture,
//   chemical: image => image.chemical,
//   mechanical: image => image.mechanical,
//   all: image => image
// };

const getFilterSections = (images) => {
    const filters = ['all']
    images.map(image => {
        if (filters.indexOf(image.name) === -1) {
            filters.push(image.name)
        }
        return(0)
    })
    return filters
}

const filterImages = (filterKey, images) => {
    return images.filter(image =>
        filterKey === 'all' ? image : image.name === filterKey
    );


}

const fetchCasos = async () => {
    const data = await flApp.content.get({schemaKey: 'servicioOProducto'})

    const test = images

    for  (let item in data) {

        await flApp.storage.getURL({
            fileId: data[item]["imagen"][0]["id"],})
            .then((url)=> {

            const tmp2 = {
                id:  data[item]["id"],
                name: data[item]["categoria"],
                mechanical: true,
                title: data[item]["titulo"],
                subTitle: data[item]["subTitulo"],
                imgUrl: url,
                link: '/Caso-Exito/'+data[item]["url"]
            }
            test.push(tmp2)
        })

    }
    return(test)

}



const Portfolio = props => {
    const [state, setState] = useState({
        list: images,
        filterKey: "all"
    });


    const {list, filterKey} = state;

    const filteredList = filterImages(filterKey, list)
    let filters = getFilterSections(images)
    const collumnCls = props.col ? props.col : "col-md-3";

    useEffect(() => {
        fetchCasos().then((images) => {
            filters = getFilterSections(images)
            setState({
                list: images,
                filterKey: "all"
            })
        })
    }, []);

    return (
        <div className="content-block">
            <div className="section-full content-inner-2 portfolio text-uppercase bg-gray" id="portfolio">
                <div className="container">
                    <div className="site-filters clearfix center  m-b40">
                        {/* FILTERS */}
                        <ul className="filters" data-toggle="buttons">
                            {filters.map(filter => (
                                <li className="btn" onClick={() => setState({...state, filterKey: filter})} key={filter}>
                                    <div className="site-button-secondry button-sm radius-xl">
                                        <span>{filter}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* FILTERED PORTFOLIO LIST */}
                    <div className="portfolio_area">
                        <div className="row portfolio-grid">
                            {filteredList.map(image => (
                                <div className={collumnCls} key={image.id}>
                                    <div className="dlab-box dlab-gallery-box">
                                        <div className="dlab-media dlab-img-overlay1 dlab-img-effect">
                                            <Link to={image.link}> <img src={image.imgUrl} alt=""/> </Link>
                                            <div className="overlay-bx">
                                                <div className="overlay-icon">
                                                    <div className="text-white">
                                                        <Link to={image.link}><i
                                                            className="fa fa-link icon-bx-xs" /></Link>
                                                        <span className="check-km" title="Factory Managment">
                                            <i className="fa fa-picture-o icon-bx-xs" />
                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dez-info p-a30 bg-white">
                                            <p className="dez-title m-t0"><Link to={image.link}>{image.title}</Link></p>
                                            <p><small>{image.subTitle}</small></p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default Portfolio;
