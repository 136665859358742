import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

class Team9 extends Component {

    render() {

        return (
            <>
                <div class="row">
					<div class="col-lg-3 col-md-6 col-sm-6">
						<div class="dlab-box m-b30 dlab-team9">
							<div class="dlab-media dlab-media-right">
								<a href="javascript:;">
									<img alt="" src={require("../../images/our-team/pic1.jpg")}/>
								</a>
								<div class="dlab-info-has">
									<ul class="dlab-social-icon">
										<li><a class="site-button fa fa-facebook" href="javascript:void(0);"></a></li>
										<li><a class="site-button fa fa-twitter" href="javascript:void(0);"></a></li>
										<li><a class="site-button fa fa-linkedin" href="javascript:void(0);"></a></li>
										<li><a class="site-button fa fa-pinterest" href="javascript:void(0);"></a></li>
									</ul>
								</div>
								<div class="clearfix">
									<h4 class="dlab-title"><a href="javascript:;">Nashid Martines</a></h4>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6">
						<div class="dlab-box m-b30 dlab-team9">
							<div class="dlab-media dlab-media-right">
								<a href="javascript:;">
									<img alt="" src={require("../../images/our-team/pic2.jpg")}/>
								</a>
								<div class="dlab-info-has">
									<ul class="dlab-social-icon">
										<li><a class="site-button fa fa-facebook" href="javascript:void(0);"></a></li>
										<li><a class="site-button fa fa-twitter" href="javascript:void(0);"></a></li>
										<li><a class="site-button fa fa-linkedin" href="javascript:void(0);"></a></li>
										<li><a class="site-button fa fa-pinterest" href="javascript:void(0);"></a></li>
									</ul>
								</div>
								<div class="clearfix">
									<h4 class="dlab-title"><a href="javascript:;">Konne Backfield</a></h4>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6">
						<div class="dlab-box m-b30 dlab-team9">
							<div class="dlab-media dlab-media-right">
								<a href="javascript:;">
									<img alt="" src={require("../../images/our-team/pic3.jpg")}/>
								</a>
								<div class="dlab-info-has">
									<ul class="dlab-social-icon">
										<li><a class="site-button fa fa-facebook" href="javascript:void(0);"></a></li>
										<li><a class="site-button fa fa-twitter" href="javascript:void(0);"></a></li>
										<li><a class="site-button fa fa-linkedin" href="javascript:void(0);"></a></li>
										<li><a class="site-button fa fa-pinterest" href="javascript:void(0);"></a></li>
									</ul>
								</div>
								<div class="clearfix">
									<h4 class="dlab-title"><a href="javascript:;">Hackson Willingham</a></h4>									
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6">
						<div class="dlab-box m-b30 dlab-team9">
							<div class="dlab-media dlab-media-right">
								<a href="javascript:;">
									<img alt="" src={require("../../images/our-team/pic4.jpg")}/>
								</a>
								<div class="dlab-info-has">
									<ul class="dlab-social-icon">
										<li><a class="site-button fa fa-facebook" href="javascript:void(0);"></a></li>
										<li><a class="site-button fa fa-twitter" href="javascript:void(0);"></a></li>
										<li><a class="site-button fa fa-linkedin" href="javascript:void(0);"></a></li>
										<li><a class="site-button fa fa-pinterest" href="javascript:void(0);"></a></li>
									</ul>
								</div>
								<div class="clearfix">
									<h4 class="dlab-title"><a href="javascript:;">Konne Backfield</a></h4>
								</div>
							</div>
						</div>
					</div>
				</div>

            </>
        )
    }
}
export default Team9;