import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer';

// Sliders
import { homeSliderContent4 } from '../element/SliderContent'
import HomeSlider from '../element/homeslider1'
import ScrollToTop from '../element/scrollToTop';
import DualSliderPotable from "../element/DualSliderPotable";
import useWindowDimensions from '../../hooks/useWindowDimensions';


const Potabilizacion = () => {

    const {width} = useWindowDimensions();

    return (
        <div style={{ overflow: 'hidden', width: '100%', position: 'relative' }}>
            <Header />

            <HomeSlider
                data={homeSliderContent4}
                contentWrapperCls="sliderStyle3"
                hideBtn
            />

            <div className="page-content bg-white">
                <div className="container">
                    <div className="content-block">
                        <br />
                        <div className="section-head text-center">
                            <h2 className="title"> Plantas de Tratamiento de Agua Potable</h2>
                            <br />
                            <p style={{ textAlign: 'justify' }}>El objetivo de un sistema de potabilización es producir, a
                                partir de una fuente de
                                suministro dada, agua con calidad en forma confiable y a un costo razonable. Para
                                lograrlo, es preciso combinar una serie de procesos y operaciones unitarias que
                                remuevan los compuestos considerados como contaminantes y que estén presentes en
                                la fuente de suministro.</p>
                        </div>
                    </div>

                </div>

                {/* Inicio Plantas Div*/}
                <div className="section-full content-inner bg-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s">
                    <div className="container">
                        <DualSliderPotable />
                    </div>
                </div>
                {/* Fin Plantas Div*/}

                <div className="section-full call-action bg-primary wow fadeIn" data-wow-duration="2s" data-wow-delay="0.9s">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 text-white">
                                <h2 className="title">Inicia tu proyecto de desarrollo</h2>
                                <p className="m-b0">Agenda una cita con nuestros especialistas.</p>
                            </div>
                            <div className="col-lg-3 d-flex">
                                <Link to="/contact-4"
                                    className="site-button white align-self-center outline ml-auto outline-2 btnhover14"> Agenda
                                    una Cita</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="procesos">
                    <div className="container">
                        <div className="row d-flex align-items-center justify-content-between">
                            <div className="col-lg-6" style={{ paddingTop: '20px', paddingLeft: width <= 767 ? '30px' : '0px' }}>
                                <h1 className="title text-black">Procesos de potabilización</h1>
                                <ul>
                                    <li>Ulttrafiltración</li>
                                    <li>Osmosis inversa</li>
                                    <li>Ozonización</li>

                                </ul>

                                <Link to="Servicios-y-productos" class="site-button outline outline-2"> Conócenos </Link>
                                <br />
                            </div>


                            <div className="col-lg-6 d-flex align-items-center justify-content-between">
                                <img style={{ marginTop: "85px" }} src={require("../../images/purify/purify1.png")} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer hideContactInfo displayNewsLetter />
            <ScrollToTop className="style1 radius white" />
        </div>
    )
}


export default Potabilizacion;