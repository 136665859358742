import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import ClientSlider1 from '../element/clientSlider1';
import { Parallax } from 'react-parallax';
import CounterSection from '../element/counterSection';
import ProviderSlider from '../element/providerSlider';
import '../../css/plugins.css';
import '../../css/style.css';
import '../../css/skin/skin-1.css';
import '../../css/templete.min.css';
import ScrollToTop from '../element/scrollToTop';
import styled from 'styled-components';
import styles from '../../css/index.module.css';
import Diferenciadores from './servicios/Diferenciadores';
import img from '../../images/home-plant.jpg';


const bg = require('../../images/background/bg2.jpg')
const bg2 = require('../../images/background/bg4.jpg')
const bg3 = require('../../images/background/map-bg.png')
const bg4 = require('../../images/home-plant.jpg');


const Container = styled.div`
    width: 100%;
    position: relative;
    background: white;

    .img-experience{
        @media screen and (max-width: 767px){
            display: none !important;
        }
    }

    .banner-div{
        height: 70vh;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @media screen and (max-width: 767px){
            display: none;
        }

        .banner-text{
            width: 50%;
            background-color: rgba(56, 56, 56, 0.2);
            padding: 20;

            .text1{
                font-size: 40px;
                color: white;
                font-weight: 700;
                width: 100%;
            }
        }
    }

    .imgMobile{
        display: none;
    }

 


    @media screen and (max-width: 767px){
        .imgMobile{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            width: 100%;
            height: 100vh;
            overflow: 'hidden';
            background-image: url(${img});

            .banner-text{
                width: 100%;
                background-color: rgba(56, 56, 56, 0.4);
                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 30px;


                .text1{
                    font-size: 30px;
                    color: white;
                    font-weight: 700;
                    width: 100%;
                    line-height: 34px;
                }
            }

            
        }

        .img-desk{
            display: none;
        }
    }


`;


const SliderClientes = styled.div`
    width: 100%;
    background: white;
    height: 300px;
    padding-bottom: 10px;
    border-bottom: 1px solid#EEEEEE;
 

    h2{
        font-size: 28px;
        color: #333;
        font-weight: 600;
        width: 100%;
        text-align: center;
        padding-top: 50px;
        margin-bottom: 30px;

        @media screen and (max-width: 767px){
            font-size: 20px;
            width: 90%;
            line-height: 24px;
            padding-top: 40px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }

    }

`

const SliderMarcas = styled.div`
    width: 100%;
    background: white;
    height: 300px;
    margin-top: 50px;
    border-bottom: 1px solid#EEEEEE;
 
        h2{
            font-size: 28px;
            color: #333;
            font-weight: 600;
            width: 100%;
            text-align: center;
            padding-top: 10px;
            margin-bottom: 30px;

            @media screen and (max-width: 767px){
                font-size: 20px;
                width: 90%;
                line-height: 24px;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }

        }
`;

const Dudas = styled.div`
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 767px){
        height: 300px;
        .parrafo{
            width: 90% !important;
        }
    }
`


const ContactComponent = ({ title, img, text }) => {
    return (
        <>
            <div style={{ width: '30%' }}>
                <img src={img} alt="" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                <div>
                    <p style={{ fontWeight: 'bold', fontSize: 22, color: '#333', margin: 0 }}>{title}</p>
                </div>
                <div>
                    <p style={{ fontSize: 16, lineHeight: '20px' }}>{text}</p>
                </div>
            </div>
        </>
    )
}
class Index extends Component {

    render() {
        return (
            <Container className="skin-1">
                <Header />

                <div className="imgMobile">

                    <div className="banner-text">
                        <p className="text1">SOLUCIONES SUSTENTABLES</p>
                        <p className="text1"> En tratamiento de agua </p>
                        <p style={{ fontSize: 22, fontWeight: 400, color: 'white', width: '100%' }}>Con nuestra amplia experiencia diseñamos, construímos y equipamos plantas a la medida correcta de las necesidades de tu proyecto utilizando la mejor tecnología.</p>
                    </div>
                    <div style={{ width: 250, marginTop: 20, paddingLeft: 20 }}>
                        <Link to="/contact-4" class="site-button appointment-btn btnhover13" style={{ width: 250, fontSize: 18 }}>AGENDAR CITA</Link>
                    </div>

                </div>

                <Parallax bgImage={bg4} strength={200} style={{ padding: "100px" }} className="img-desk" >
                    <div className="banner-div">
                        <div className="banner-text">
                            <p className="text1">SOLUCIONES SUSTENTABLES</p>
                            <p className="text1"> En tratamiento de agua </p>
                            <p style={{ fontSize: 22, fontWeight: 400, color: 'white', width: '80%' }}>Con nuestra amplia experiencia diseñamos, construímos y equipamos plantas a la medida correcta de las necesidades de tu proyecto utilizando la mejor tecnología.</p>
                        </div>
                        <div style={{ width: 250, marginTop: 20, marginLeft: 10 }}>
                            <Link to="/contact-4" class="site-button appointment-btn btnhover13" style={{ width: 250 }}>AGENDAR CITA</Link>
                        </div>
                    </div>
                </Parallax>

                {/* //Slider Clientes */}

                <SliderClientes>
                    <h2>Más de 100 empresas han confiado en nosotros</h2>

                    <div class="section-full bg-img-fix wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s">
                        <div class="container">
                            <ClientSlider1 hideBorder />
                        </div>
                    </div>
                </SliderClientes>

                {/*Inicia Años de experiencia*/}

                <div className="container" style={{ marginTop: 100, marginBottom: 200, background: 'white' }}>
                    <div className="row">
                        <div class="col-lg-6">
                            <h2 class="title">Contamos con más de <br /><span class="text-primary"> 35 años de experiencia</span></h2>
                            <br />
                            <p style={{ textAlign: 'left' }}> Gracias a nuestros 35 años de experiencia, somos expertos en el manejo sustentable del agua a nivel industrial, comercial, municipal, hospitalario y doméstico.
                                Estamos comprometidos con dar un servicio de la más alta calidad a nuestros clientes, somos líderes en la industria gracias a los 30 años de experiencia que nos respaldan.</p>
                            <div className="contact-right">
                                <Link to="/nosotros" class="site-button appointment-btn btnhover13">Conoce más</Link>
                            </div>
                        </div>

                        <div className="col-lg-6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%' }} className="img-experience">
                            <img style={{ boxShadow: '0px 6px 10px #808080', borderRadius: 8 }} src={require('../../images/EXPERIENCIA.JPG')} alt="" />
                        </div>
                    </div>
                </div>

                {/* Banner diferenciadores */}
                <Diferenciadores />


                {/* Slider marcas */}

                <SliderMarcas>
                    <h2>Trabajamos con las mejores marcas</h2>
                    <div class="section-full bg-img-fix wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s">
                        <div class="container">
                            <ProviderSlider hideBorder />
                        </div>
                    </div>
                </SliderMarcas>


                <Dudas>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <p style={{ textAlign: 'center', margin: 0, fontSize: 26, fontWeight: 'bold', color: '#333' }}>¿Tienes alguna duda?</p>
                        <p className="parrafo" style={{ textAlign: 'center', margin: 0, width: '40%', marginTop: 20, lineHeight: 1.2, marginBottom: 50 }}>Nuestros expertos están listos para conversar sobre cómo Eco-intollutions puede ayudar a su compañía a alcanzar sus objetivos.</p>
                    </div>
                    <div>
                        <Link to="/contact-4" class="site-button appointment-btn btnhover13">Contacte a un especialista</Link>
                    </div>
                </Dudas>


                {/* <div className="container" style={{ paddingTop: 60, marginTop: 80, paddingBottom: 60 }}>
                    <div className="row">
                        <div className="col-lg-4" style={{ display: 'flex', flexDirection: 'row' }}>
                            <ContactComponent
                                title="Dirección"
                                img={require('../../images/icon/location.png')}
                                text="Minerva No.72 Col. Crédito constructor, Alcaldía Benito Juárez, CP: 03940 Ciudad de México" />
                        </div>
                        <div className="col-lg-4" style={{ display: 'flex', flexDirection: 'row' }}>
                            <ContactComponent
                                title="Teléfonos"
                                img={require('../../images/icon/phone.png')}
                                text="55-56-63-53-09    /    55-56-61-10-69" />
                        </div>
                        <div className="col-lg-4" style={{ display: 'flex', flexDirection: 'row' }}>
                            <ContactComponent
                                title="Dirección"
                                img={require('../../images/icon/email.png')}
                                text="ventas@ecointell.com.mx contacto@ecointell.com.mx" />
                        </div>

                    </div>

                </div> */}

                <Footer hideContactInfo />
                <ScrollToTop className="icon-up" />
            </Container>
        )
    }
}
export default Index;

