import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

class Team5 extends Component {

    render() {

        return (
            <>
                <div class="row">
					<div class="col-lg-3 col-md-6 col-sm-6">
						<div class="dlab-box m-b30 dlab-team5">
							<div class="dlab-border-left"></div>
							<div class="dlab-border-right"></div>
							<div class="dlab-media">
								<a href="javascript:;">
									<img width="358" height="460" alt="" src={require("../../images/our-team/pic1.jpg")}/>
								</a>
							</div>
							<div class="dlab-info">
								<h4 class="dlab-title"><a href="javascript:;">Nashid Martines</a></h4>
								<span class="dlab-position">Director</span>
								<ul class="dlab-social-icon">
									<li><a class="site-button circle-sm white fa fa-facebook" href="javascript:void(0);"></a></li>
									<li><a class="site-button circle-sm white fa fa-twitter" href="javascript:void(0);"></a></li>
									<li><a class="site-button circle-sm white fa fa-linkedin" href="javascript:void(0);"></a></li>
									<li><a class="site-button circle-sm white fa fa-pinterest" href="javascript:void(0);"></a></li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6">
						<div class="dlab-box m-b30 dlab-team5">
							<div class="dlab-border-left"></div>
							<div class="dlab-border-right"></div>
							<div class="dlab-media">
								<a href="javascript:;">
									<img alt="" src={require("../../images/our-team/pic2.jpg")}/>
								</a>
							</div>
							<div class="dlab-info">
								<h4 class="dlab-title"><a href="javascript:;">Konne Backfield</a></h4>
								<span class="dlab-position">Designer</span>
								<ul class="dlab-social-icon">
									<li><a class="site-button circle-sm white fa fa-facebook" href="javascript:void(0);"></a></li>
									<li><a class="site-button circle-sm white fa fa-twitter" href="javascript:void(0);"></a></li>
									<li><a class="site-button circle-sm white fa fa-linkedin" href="javascript:void(0);"></a></li>
									<li><a class="site-button circle-sm white fa fa-pinterest" href="javascript:void(0);"></a></li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6">
						<div class="dlab-box m-b30 dlab-team5">
							<div class="dlab-border-left"></div>
							<div class="dlab-border-right"></div>
							<div class="dlab-media">
								<a href="javascript:;">
									<img alt="" src={require("../../images/our-team/pic3.jpg")}/>
								</a>
							</div>
							<div class="dlab-info">
								<h4 class="dlab-title"><a href="javascript:;">Hackson Willingham</a></h4>
								<span class="dlab-position">Developer</span>
								<ul class="dlab-social-icon">
									<li><a class="site-button circle-sm white fa fa-facebook" href="javascript:void(0);"></a></li>
									<li><a class="site-button circle-sm white fa fa-twitter" href="javascript:void(0);"></a></li>
									<li><a class="site-button circle-sm white fa fa-linkedin" href="javascript:void(0);"></a></li>
									<li><a class="site-button circle-sm white fa fa-pinterest" href="javascript:void(0);"></a></li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6">
						<div class="dlab-box m-b30 dlab-team5">
							<div class="dlab-border-left"></div>
							<div class="dlab-border-right"></div>
							<div class="dlab-media">
								<a href="javascript:;">
									<img alt="" src={require("../../images/our-team/pic4.jpg")}/>
								</a>
							</div>
							<div class="dlab-info">
								<h4 class="dlab-title"><a href="javascript:;">Konne Backfield</a></h4>
								<span class="dlab-position">Manager</span>
								<ul class="dlab-social-icon">
									<li><a class="site-button circle-sm white fa fa-facebook" href="javascript:void(0);"></a></li>
									<li><a class="site-button circle-sm white fa fa-twitter" href="javascript:void(0);"></a></li>
									<li><a class="site-button circle-sm white fa fa-linkedin" href="javascript:void(0);"></a></li>
									<li><a class="site-button circle-sm white fa fa-pinterest" href="javascript:void(0);"></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>

            </>
        )
    }
}
export default Team5;