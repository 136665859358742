import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import responsive from './slider-resonsive'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const content1 = [
    {
        icon: "",
        text: 'HYDRO-ACTION',
    },
    {
        icon: "",
        text: 'TANQUES AUSTRALIANOS',
    },
    {
        icon: "",
        text: 'CONTENEDOR MARINO / ACERO',
    },
    {
        icon: "",
        text: 'PLANTAS DE CONCRETO ARMADO (Tradicional)',
    },

]

const content2 = [
    {
        image :require("../../images/about/pic6.jpg"),
        shortTitle :"Rango de gastos de 1 a 20 m3/d",
        title:"HYDRO-ACTION",
        subTitle:(
            <ul> Ventajas <br/>
                <li>Reducción de espacios por procesos integrados</li>
                <li>Optimización de recursos</li>
                <li>Operación y mantenimiento a menor costo</li>
                <li>Optimización de energía</li>
                <li>Optimización de superficie ya que pueden ir enterradas o superficiales</li>

            </ul>),
        description:"Planta prefabricada que alcanza un magnifico nivel de tratamiento a través de un proceso  biológico específico.\n" +
            "                                                    Se suministra una aireación continua a través de unos difusores de teflón. La sedimentación se logra en un sistema\n" +
            "                                                    de clarificación de forma cónica, esto inmerso en los compartimentos de aireación"
    },
    {
        image :require("../../images/about/pic7.jpg"),
        shortTitle :"Rango de gastos de 15 A 200 m3/d",
        title:"TANQUES AUSTRALIANOS",
        subTitle:( <ul> Ventajas <br/>
            <li>Todos los elementos de la estructura son desarmables y modulares</li>
            <li>Mantenimiento a menor costo</li>
            <li>Optimización de superficie ya que pueden ir enterradas o superficiales</li>
        </ul>),
        description:"Los tanques cilíndricos australianos son fabricados con láminas de acero,\n" +
            "                                                    corrugadas y  galvanizadas con calidad G- 200 por inmersión en caliente,\n" +
            "                                                    contienen un recubrimiento interno epóxico diseñado y aceptado para estar\n" +
            "                                                    en contacto con el agua, y garantizando la NO contaminación del agua almacenada."
    },
    {
        image: require("../../images/about/pic9.jpg"),
        shortTitle: "Rango de gastos de 20-500m3/d",
        title: "CONTENEDOR MARINO / ACERO",
        subTitle: (<ul> Ventajas
            <li>1.-</li></ul>),
        description: "Descripción",
    },
    {
        image :require("../../images/about/pic8.jpg"),
        shortTitle :" Rango de gastos de 200  en  ADELANTE m3/d",
        title:"PLANTAS DE CONCRETO ARMADO",
        subTitle:(<ul> Ventajas <br/>
            <li>·Tiempo de vida útil mayor</li>
            <li>·Se diseña y construye con el mejor aprovechamiento de los recursos</li>
        </ul>),
        description:"Los elementos que constituyen esta opción de planta son elaborados con\n" +
            "                                                    losa de cimentación de concreto armado, varilla, etc que dan como resultado\n" +
            "                                                    uno de los mejores y más duraderos sistemas"
    },

]


  
class DualSlider extends Component {

    constructor(props) {
        super(props);
        this.state = {
          nav1: null,
          nav2: null
        };
    }

    componentDidMount() {
        this.setState({
          nav1: this.slider1,
          nav2: this.slider2
        });
    }

    render() {

        const settings = {
            infinite: true,
            speed: 0,
            slidesToShow: 4,
            slidesToScroll: 4,
            autoplay: false,
            responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 4
				  }
				},
				{
				  breakpoint: 600,
				  settings: {
					slidesToShow: 4
				  }
				},
				{
				  breakpoint: 480,
				  settings: {
					slidesToShow: 4
				  }
				}
			]
            
        };

        const settings2 = {
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
        };

        return (
            <>
                <Slider
                {...settings} 
                className="project-list"
                asNavFor={this.state.nav2}
                ref={slider => (this.slider1 = slider)}>
                    {content1.map((item, id) => (
                        <div class="item " onClick={e=> this.slider2.slickGoTo(id)}>
                            <div class="project-owbx" >
                                <h4 class="title">{item.text}</h4>
                            </div>
                        </div>
                    ))}
                </Slider>

                <Slider 
                {...settings2} 
                className="primary"
                asNavFor={this.state.nav1}
                ref={slider => (this.slider2 = slider)}>
                    {/* Hacer variable list con ul*/}
                    {content2.map((item, id) => (
                        <div class="item">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-6 m-b30">
                                    <div class="our-story">
                                        <span>{item.shortTitle}</span>
                                        <h2 class="title">{item.title}</h2>
                                        <h5 class="title">{item.subTitle}</h5>
                                        <p>{item.description}</p>
                                        <Link to="/about-2" class="site-button btnhover16">Read More</Link>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 m-b30">
                                    <img src={item.image} class="radius-sm" alt=""/>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>

            </>
        )
    }
}
export default DualSlider;