import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const content = [
    {
        logo: require('./../../images/client-logo/logo-wallmart.png'),
    },
    {
        logo: require('./../../images/client-logo/Logo-FUNO.png'),
    },
    {
        logo: require('./../../images/client-logo/Logo-Finsa.png'),
    },
    {
        logo: require('./../../images/client-logo/Logo-YAZAKI.png'),
    },
    {
        logo: require('./../../images/client-logo/Logo-Conagua.png'),
    },
    {
        logo: require('./../../images/client-logo/Logo-SEP.jpg'),
    },
    {
        logo: require('./../../images/client-logo/Logo-SACMEX.jpg'),
    },
    {
        logo: require('./../../images/client-logo/Logo-CAPUFE.png'),
    },
    {
        logo: require('./../../images/client-logo/Logo-NL.gif'),
    },
    {
        logo: require('./../../images/client-logo/Logo-ZIKLUM.png'),
    },
    {
        logo: require('./../../images/client-logo/Logo-vidalta.jpg'),
    },
    {
        logo: require('./../../images/client-logo/Logo-urbi-vida-residencial.jpg'),
    },
    {
        logo: require('./../../images/client-logo/Logo-SORIANA.png'),
    },
    {
        logo: require('./../../images/client-logo/Logo-liverpool.jpg'),
    },
    {
        logo: require('./../../images/client-logo/Logo-LA COMER.jpeg'),
    },
    {
        logo: require('./../../images/client-logo/Logo-INIX.png'),
    },
    {
        logo: require('./../../images/client-logo/Logo-Homex.jpg'),
    },
    {
        logo: require('./../../images/client-logo/Logo-GEO.JPG'),
    },
    {
        logo: require('./../../images/client-logo/Logo-GARCIA LOPEZ.png'),
    },
    {
        logo: require('./../../images/client-logo/LOGO-Clubdgolf.png'),
    },
]
class ClientSlider1 extends Component {

    render() {

        const settings = {
            dots: false,
            infinite: true,
            speed: 1000,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        };

        const Border = this.props.hideBorder ? "" : "border"
        const padding = this.props.allowPadding ? "p-a25":""

        return (
            <>
                <Slider {...settings} className="client-logo-carousel btn-style-1 icon-2" style={{backgroundColor: "white", borderWidth: 0}}>
                    {content.map((item, id) => (
                        <div class="item">
                            <div class={`ow-client-logo ${padding}`} style={{backgroundColor: 'white'}}>
                                <div class={`client-logo ${Border}`}>
                                    <Link to="#"><img src={item.logo} alt=""/></Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>

            </>
        )
    }
}
export default ClientSlider1;